<template>
  <v-navigation-drawer
    v-resize="resize"
    :value="drawer"
    :color="$vuetify.theme.dark ? null : 'white'"
    app
    width="260"
    @input="drawer = $event"
  >
    <div class="d-flex flex-column" :style="{ height: height }">
      <div>
        <v-list-item class="px-6 app-logo-item">
          <v-list-item-content v-if="location" class="py-6">
            <template v-if="$brand.monoBrand">
              <div class="d-flex justify-center">
                <AppLogo :src="location.logo" />
              </div>
            </template>
            <template v-else>
              <AppLocation>
                <div class="d-flex justify-center">
                  <AppLogo :src="location.logo" />
                </div>
              </AppLocation>
            </template>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div class="py-4" style="position: relative">
        <v-divider style="position: absolute; left: 0; right: 0; top: calc(50% - 1px / 2)" />
        <div class="px-4">
          <v-select
            v-if="menuGroupsItems.length > 1"
            :value="selectedGroup"
            :items="menuGroupsItems"
            background-color="t-back-light"
            dense
            outlined
            single-line
            hide-details
            @input="onSelectGroup"
          />
        </div>
      </div>

      <div class="flex-grow-1 d-flex flex-column">
        <AppMenuList :items="selectedGroupItems" class="py-0" />
        <v-spacer />
        <AppMenuList :items="shownAppendItems" class="pb-6">
          <AppMenuListItem
            v-if="selectedGroup === GROUP_USER && location && location.supportCategoryId"
            :to="{ name: 'user.support' }"
          >
            <template #icon>
              <v-icon>mdi-face-agent</v-icon>
            </template>
            <span>{{ $t('nav.user.support') }}</span>
          </AppMenuListItem>
        </AppMenuList>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { RG } from '@/constants/routeGroup';
import { asArray, uniqueFilter } from '@/utils/arrays';
import AppLogo from '@/components/ui/AppLogo';
import AppMenuList from '@/components/layout/menu/AppMenuList';
import AppMenuListItem from '@/components/layout/menu/AppMenuListItem';
import AppLocation from '@/components/app/AppLocation';

export default {
  name: 'Navigation',

  components: {
    AppLocation,
    AppMenuListItem,
    AppMenuList,
    AppLogo,
  },

  data: () => ({
    height: '100%',
    selectedGroup: null,
    GROUP_USER: RG.user,
  }),

  computed: {
    ...mapFields('app', ['drawer']),

    ...mapGetters('auth', ['location']),

    ...mapGetters('permissions', ['canAccess']),

    menuItems() {
      return require('@/brand/menu/items').default.call(this);
    },

    resolvedItems() {
      return this.menuItems
        .map(x => {
          const route = this.$router.match(x.route);
          const group = this.getRouteGroup(route);

          return {
            ...x,
            show: route.matched.length > 0 && this.canViewItem(x),
            group: group,
          };
        })
        .filter(x => x.show);
    },

    selectedGroupItems() {
      return this.resolvedItems.filter(x => x.group === this.selectedGroup);
    },

    shownGroups() {
      return this.resolvedItems.map(x => x.group).filter(uniqueFilter);
    },

    menuGroupsItems() {
      return [
        { text: this.$t('nav.group.user'), value: RG.user },
        { text: this.$t('nav.group.admin'), value: RG.admin },
        { text: this.$t('nav.group.operator'), value: RG.operator },
      ].filter(x => this.shownGroups.includes(x.value));
    },

    appendItems() {
      switch (this.selectedGroup) {
        case RG.user:
          return [
            this.location?.aboutTitle && {
              title: this.location.aboutTitle,
              route: { name: 'about' },
              icon: 'mdi-book-open-page-variant-outline',
            },
            ...(this.location?.staticPages ?? []).map(page => ({
              title: page.title,
              route: { name: 'static-page', params: { id: page.id } },
              icon: 'mdi-book-open-page-variant-outline',
            })),
            {
              title: this.$t('nav.user.locationPlan'),
              route: { name: 'location-plan' },
              icon: 'mdi-floor-plan',
              guard: 'location:floor-scheme.list',
            },
            this.location?.geoContact && {
              title: this.$t('nav.user.contacts'),
              route: { name: 'contacts' },
              icon: 'mdi-map-marker-outline',
            },
          ].filter(x => x);

        case RG.admin:
          return [
            {
              title: this.$t('nav.admin.systemSettings'),
              route: { name: 'admin.location.info' },
              icon: 'mdi-cog-outline',
              guard: 'auth:admin.location.update',
              secureOfferor: this.location?.secureOfferor || 'none',
            },
            {
              title:
                this.$t('Техподдержка') + (this.$brandConfig.isOlveryPlatform ? ' Olvery' : ''),
              route: { name: 'admin.support' },
              icon: 'mdi-face-agent',
              guard: 'otrs:servicing.tech-support.create',
            },
          ];

        case RG.operator:
          return [
            {
              title:
                this.$t('Техподдержка') + (this.$brandConfig.isOlveryPlatform ? ' Olvery' : ''),
              route: { name: 'operator.support' },
              icon: 'mdi-face-agent',
              guard: 'otrs:servicing.tech-support.create',
            },
          ];
      }

      return [];
    },

    shownAppendItems() {
      return this.appendItems.filter(x => this.canViewItem(x));
    },

    defaultGroup() {
      return this.resolvedItems.find(x => x.group)?.group ?? RG.user;
    },
  },

  watch: {
    $route() {
      const group = this.getRouteGroup(this.$route);
      if (group) {
        this.selectedGroup = group;
      }
    },

    appendItems: {
      immediate: true,
      handler() {
        this.appendItems
          .filter(x => x.secureOfferor)
          .forEach(x => this.fetchSecureOfferors({ operation: x.guard }));
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.drawer = !this.$vuetify.breakpoint.mobile;
    });

    this.selectedGroup = this.getRouteGroup(this.$route) ?? this.defaultGroup;
  },

  methods: {
    ...mapActions('permissions', ['fetchSecureOfferors']),

    getRouteGroup(route) {
      return route.matched.reduce((acc, x) => (x.meta?.group ? x.meta.group : acc), null);
    },

    resize() {
      const newHeight = `${window.innerHeight}px`;
      if (this.height !== newHeight) {
        // fixes iPad, iPhone
        this.height = `${window.innerHeight}px`;
      }
    },

    canViewItem(item) {
      const userIds = [];
      if (item.userIds) {
        userIds.push(...item.userIds);
      }
      if (userIds.length > 0 && !userIds.includes(this.$userId)) {
        return false;
      }

      const operations = [];
      if (item.guard) {
        operations.push(...asArray(item.guard));
      }
      if (item.children) {
        item.children.forEach(x => operations.push(...asArray(x.guard)));
      }

      return operations.length ? operations.some(x => this.canAccess(x, item.secureOfferor)) : true;
    },

    onSelectGroup(val) {
      this.selectedGroup = val;
      const first = this.selectedGroupItems?.[0];
      if (first) {
        this.$router.push(first.route);
      }
    },
  },
};
</script>
